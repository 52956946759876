import {
  PRODUCTIVA,
  SANITARIA,
  NUTRICIONAL,
  ECONOMICA,
  ESTABLISHMENT,
} from 'modules/core/constants';

// ADQUISICION
import { default as GenericAdquisicionFilter } from './GenericAdquisicionFilter';

// MOVIMIENTOS
import { default as RodeoFilter } from './Movimientos/RodeoFilter';
import { default as UbicacionFilter } from './Movimientos/UbicacionFilter';

// PRODUCTIVA
import { default as AsignacionFilter } from './Productiva/AsignacionFilter';
import { default as BajaFilter } from './Productiva/BajaFilter';
import { default as DatoAdicionalFilter } from './Productiva/DatoAdicionalFilter';
import { default as DEPsFilter } from './Productiva/DEPsFilter';
import { default as DesteteFilter } from './Productiva/DesteteFilter';
import { default as EsquilaFilter } from './Productiva/EsquilaFilter';
import { default as PartoFilter } from './Productiva/PartoFilter';
import { default as PesajeFilter } from './Productiva/PesajeFilter';
import { default as PreniezFilter } from './Productiva/PreniezFilter';
import { default as SeleccionHembrasFilter } from './Productiva/SeleccionHembrasFilter';
import { default as SeleccionMachosFilter } from './Productiva/SeleccionMachosFilter';
import { default as ServicioFilter } from './Productiva/ServicioFilter';
import { default as TactoFilter } from './Productiva/TactoFilter';
import { default as TransferenciaEmbrionariaFilter } from './Productiva/TransferenciaEmbrionariaFilter';

// SANITARIA
import { default as ControlEpidemiologicoFilter } from './Sanitaria/ControlEpidemiologicoFilter';
import { default as SanidadIndividualFilter } from './Sanitaria/SanidadIndividualFilter';
import { default as TratamientoColectivoFilter } from './Sanitaria/TratamientoColectivoFilter';
import { default as VacunacionFilter } from './Sanitaria/VacunacionFilter';

// NUTRICIONAL
import { default as DietaFilter } from './Nutricional/DietaFilter';

// ECONOMICA
import { default as GenericEconomicaFilter } from './Economica/GenericEconomicaFilter';

const getCompraInsumoFilters = () =>
  ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.EVENT_TYPES.reduce(
    (accum, el) => ({
      ...accum,
      [el]: [GenericEconomicaFilter, GenericAdquisicionFilter],
    }),
    {}
  );

const RECORDS_FILTERS = {
  [ESTABLISHMENT.RODEOS]: [RodeoFilter],
  [ESTABLISHMENT.UBICACIONES]: [UbicacionFilter],
  [PRODUCTIVA.ASIGNACION]: [AsignacionFilter],
  [PRODUCTIVA.BAJA]: [BajaFilter],
  [PRODUCTIVA.DATOS_ADICIONALES]: [DatoAdicionalFilter],
  [PRODUCTIVA.DEPS]: [DEPsFilter],
  [PRODUCTIVA.DESTETE]: [DesteteFilter],
  [PRODUCTIVA.ESQUILA]: [EsquilaFilter],
  [PRODUCTIVA.PARTO]: [PartoFilter],
  [PRODUCTIVA.PESAJE]: [PesajeFilter],
  [PRODUCTIVA.PRENIEZ]: [PreniezFilter],
  [PRODUCTIVA.SELECCION_HEMBRAS]: [SeleccionHembrasFilter],
  [PRODUCTIVA.SELECCION_MACHOS]: [SeleccionMachosFilter],
  [PRODUCTIVA.SERVICIO]: [ServicioFilter],
  [PRODUCTIVA.SERVICIOCOLECTIVO]: [ServicioFilter],
  [PRODUCTIVA.TACTO]: [TactoFilter],
  [PRODUCTIVA.TRANSFERENCIAEMBRIONARIA]: [TransferenciaEmbrionariaFilter],
  [SANITARIA.CONTROL_EPIDEMIOLOGICO]: [ControlEpidemiologicoFilter],
  [SANITARIA.SANIDAD_INDIVIDUAL]: [SanidadIndividualFilter],
  [SANITARIA.TRATAMIENTO_COLECTIVO]: [TratamientoColectivoFilter],
  [SANITARIA.VACUNACION]: [VacunacionFilter],
  [NUTRICIONAL.DIETA]: [DietaFilter],
  [ECONOMICA.INGRESOS_VENTA_UNIDAD]: [GenericEconomicaFilter],
  [ECONOMICA.EGRESO_COMPRA_UNIDAD]: [GenericEconomicaFilter],
  [ECONOMICA.INGRESOS_OTROS]: [GenericEconomicaFilter],
  [ECONOMICA.EGRESO_OTROS]: [GenericEconomicaFilter],
  ...getCompraInsumoFilters(),
};

const getRecordFilters = (recordType) => RECORDS_FILTERS[recordType] || [];

export default getRecordFilters;
