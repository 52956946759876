import React from 'react';
import { useQuery } from 'react-query';
import { Drawer, Button, Spin } from 'antd';
import { withAPI } from 'modules/api';
import { EyeOutlined } from '@ant-design/icons';
import { MASTERS } from 'modules/core/constants';
import { UnitHeader, UnitInfo, UnitExtraInfo } from './UnitInfo/UnitInfo';
import UnitTreeGraph from './UnitTreeGraph/UnitTreeGraph';
import UnitObservations from './UnitObservations/UnitObservations';
import './UnitDrawerShow.scss';
import { ModalTitle } from 'modules/core/components';

const UnitShowButtons = ({ close, edit }) => {
  return (
    <div className="unit-buttons">
      {edit && (
        <Button htmlType="button" className="unit-button" onClick={edit}>
          MODIFICAR
        </Button>
      )}
      <Button type="primary" className="unit-button" onClick={close}>
        CERRAR
      </Button>
    </div>
  );
};

const UnitDrawerShow = ({ api, unit, visible, edit = null, close }) => {
  const unitId = unit?.id;
  const unitType = unit?.sexo || unit?.type;
  const {
    data: unitInfo,
    isLoading: fetchingUnit,
    refetch: refetchUnit,
  } = useQuery(
    ['UnitDrawer::fetchUnit', { unitId, unitType, params: { bajas: true } }],
    api.fetchUnit,
    { enabled: !!(unitId && visible) }
  );
  const { data: options, isLoading } = useQuery(
    [
      'Masters::Bulk',
      {
        masterTypes: [
          MASTERS.PRODUCTIVA_TYPES.ORIGEN,
          MASTERS.PRODUCTIVA_TYPES.RAZA,
          MASTERS.PRODUCTIVA_TYPES.REGISTRO,
          MASTERS.PRODUCTIVA_TYPES.COLOR,
          MASTERS.PRODUCTIVA_TYPES.UBICACION,
          MASTERS.PRODUCTIVA_TYPES.CRIADOR,
          MASTERS.PRODUCTIVA_TYPES.RODEO,
        ],
      },
    ],
    api.fetchMastersFormOptions,
    { enabled: !!(unitId && visible), staleTime: 10000 }
  );
  const onEdit = () => {
    close();
    edit(unitInfo);
  };
  return (
    <Drawer
      title={<ModalTitle icon={<EyeOutlined />} title="Detalle de la unidad" />}
      className="unit-show-drawer"
      placement="right"
      width="85%"
      onClose={close}
      open={visible}
      closable={false}
    >
      <div className="unit-show-view">
        <Spin size="large" spinning={isLoading || fetchingUnit} />
        {options && unitInfo && (
          <>
            <UnitHeader unit={unitInfo} masters={options} />
            <div className="unit-data-container">
              <div className="unit-title">Ficha técnica</div>
              <UnitInfo unit={unitInfo} masters={options} />
              <div className="unit-title">Datos adicionales</div>
              <UnitExtraInfo data={unitInfo.datoadicional} />
              <div className="unit-title">DEPs</div>
              <UnitExtraInfo data={unitInfo.dep} numRows={4} />
              <div className="unit-title">Árbol genealógico</div>
              <UnitTreeGraph unitId={unitId} />
              <div className="unit-title">Observaciones</div>
              <UnitObservations
                unitId={unitId}
                obs={unitInfo.comentarios}
                refetch={refetchUnit}
              />
            </div>
            <UnitShowButtons edit={edit && onEdit} close={close} />
          </>
        )}
      </div>
    </Drawer>
  );
};

export default withAPI(UnitDrawerShow);
