import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Col, Row, Spin, Table } from 'antd';
import { Card } from 'modules/core/components';
import { UNITS } from 'modules/core/constants';

const HEIGHT_ROW_BASE = 170;
const HEIGHT_PER_TABLE_ROW = 47;

const EstablecimientoAnalytics = ({ api, isActive }) => {
  const { data, isLoading } = useQuery(
    ['Analytics::fetchCategoriasByUbicacionRodeo', {}],
    api.fetchCategoriasByUbicacionRodeo,
    { enabled: isActive }
  );

  const generateCategoriesColumn = (unitType) => ({
    title: UNITS.TYPES_FORMATTED[unitType],
    align: 'center',
    children: UNITS.CATEGORIES_LIST[api.userSpecies][unitType].map((c) => ({
      title: UNITS.CATEGORIES_FORMATTED[c],
      dataIndex: c,
      width: '8%',
    })),
  });

  const columns = [
    { title: 'Rodeo', dataIndex: 'nombre', ellipsis: true },
    generateCategoriesColumn(UNITS.HEMBRAS),
    generateCategoriesColumn(UNITS.MACHOS),
  ];

  if (isLoading) return <Spin />;

  return (
    <Row gutter={[24, 32]} className="data-row">
      {data.map((el, ix) => (
        <Col span={24} key={ix}>
          <Card
            type="secondary"
            title={el?.titulo}
            style={{
              height: HEIGHT_ROW_BASE + HEIGHT_PER_TABLE_ROW * el?.data.length,
            }}
          >
            <Table
              rowKey="id"
              columns={columns}
              dataSource={el?.data}
              bordered
              loading={isLoading}
              size="middle"
              pagination={false}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default withAPI(EstablecimientoAnalytics);
