import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { withAPI } from 'modules/api';
import { ECONOMICA, UNITS } from 'modules/core/constants';
import { Button } from 'antd';
import {
  EllipsisOutlined,
  CloudDownloadOutlined,
  DownloadOutlined,
  UndoOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import {
  DropdownMenu,
  ExportFieldsModal,
  UploadModal,
} from 'modules/core/components';

const COMPRA_VENTA_EVENTS = [
  ECONOMICA.INGRESOS_VENTA_UNIDAD,
  ECONOMICA.EGRESO_COMPRA_UNIDAD,
];

const OTROS_ECONOMICA_EVENTS = [
  ECONOMICA.INGRESOS_OTROS,
  ECONOMICA.EGRESO_OTROS,
];

const ECONOMICA_EVENT_FORMATTED = {
  ...ECONOMICA.EVENT_FORMATTED,
  ...ECONOMICA.EVENT_EGRESO_COMPRA_INSUMOS.EVENT_FORMATTED,
};

const getExportFns = ({
  api,
  eventType = null,
  fields = null,
  params = {},
}) => {
  const commonQueryParams = { eventType, fields, params };

  if (COMPRA_VENTA_EVENTS.includes(eventType))
    return {
      fields: api?.fieldsExportUnits,
      excel: api?.exportEventUnits,
      queryParams: { unitType: UNITS.UNIDAD, ...commonQueryParams },
    };
  if (OTROS_ECONOMICA_EVENTS.includes(eventType))
    return {
      fields: api?.fieldsExportEconomicaEvent,
      excel: api?.exportEconomicaEvent,
      queryParams: commonQueryParams,
    };
};

// NOTE:
// Compra y Venta de unidades se comporta como un evento (export y fields relacionado a unidades)
// Otros ingresos y egresos se comportan como historiales (export y fields relacionado a historiales)
// Compra de insumos, no tiene export en el listado del evento.
const EconomicalEventsMenu = ({
  api,
  eventType,
  exportParams,
  onUpload,
  onShowUndo,
  withoutExportFile = false,
}) => {
  const fileAffix = ECONOMICA_EVENT_FORMATTED[eventType];
  const [viewExport, setViewExport] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [viewUpload, setViewUpload] = useState(false);
  const { mutate: exportEvent } = useMutation(
    getExportFns({ api, eventType })?.excel
  );
  const { mutate: downloadTemplate } = useMutation(
    COMPRA_VENTA_EVENTS.includes(eventType)
      ? api.downloadEventTemplate
      : api.downloadEconomicaTemplate
  );

  const { data: availableFields } = useQuery(
    [
      'Events::fieldsExportEconomicaEvent',
      getExportFns({ eventType })?.queryParams,
    ],
    getExportFns({ api, eventType })?.fields,
    { enabled: !withoutExportFile && viewExport }
  );

  const onExportEvents = (fields) => {
    setIsExporting(true);

    const exportQueryParams = getExportFns({
      eventType,
      fields: fields?._nogroup || fields,
      params: exportParams,
    })?.queryParams;

    exportEvent(exportQueryParams, {
      onSuccess: (response) => {
        saveAs(response, `${fileAffix}-Exportados.xlsx`);
        setIsExporting(false);
        setViewExport(false);
      },
    });
  };

  const onDownloadTemplate = () => {
    const queryParams = COMPRA_VENTA_EVENTS.includes(eventType)
      ? { params: { evento: eventType, ...exportParams } }
      : { eventType, params: exportParams };

    downloadTemplate(queryParams, {
      onSuccess: (response) => saveAs(response, `${fileAffix}-Plantilla.xlsx`),
    });
  };

  return (
    <>
      <DropdownMenu
        menu={_.compact([
          {
            title: 'Plantilla para alta',
            icon: <CloudDownloadOutlined />,
            onClick: () => onDownloadTemplate(),
          },
          {
            title: 'Alta masiva',
            icon: <FileAddOutlined />,
            onClick: () => setViewUpload(true),
          },
          !withoutExportFile && {
            title: 'Exportar a Excel',
            icon: <DownloadOutlined />,
            onClick: () => setViewExport(true),
          },
          onShowUndo && {
            title: 'Deshacer',
            icon: <UndoOutlined />,
            onClick: () => onShowUndo(),
          },
        ])}
      >
        <Button icon={<EllipsisOutlined />} />
      </DropdownMenu>
      <ExportFieldsModal
        visible={viewExport}
        fields={availableFields}
        loading={isExporting}
        onSuccess={onExportEvents}
        close={() => setViewExport(false)}
      />
      <UploadModal
        title={fileAffix}
        apiFn={api.uploadEconomicaEvent}
        params={{ eventType }}
        visible={viewUpload}
        close={() => setViewUpload(false)}
        onUpload={onUpload}
      />
    </>
  );
};

export default withAPI(EconomicalEventsMenu);
