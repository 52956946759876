import React from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
import { QueryClient, QueryClientProvider } from 'react-query';
import PrivateRoute from './PrivateRoute';
import { Home } from 'modules/home';
import { Login } from 'modules/login';
import { Profile } from 'modules/user';
import { EventSync } from 'modules/sync';
import { Notifications } from 'modules/notifications';
import { Routes as RoutesAnalytics } from 'modules/analytics';
import { Routes as RoutesAdmin } from 'modules/admin';
import { Routes as RoutesUnits } from 'modules/units';
import { Routes as RoutesManagement } from 'modules/management';
import { Routes as RoutesEstablishment } from 'modules/establishment';
import { APIProvider, FincaAPI } from 'modules/api';
import {
  ESTABLISHMENT,
  GENERAL,
  MANAGEMENT,
  UNITS,
  USERS,
} from 'modules/core/constants';
import ThemeSelector from 'modules/core/theme/ThemeSelector';

import 'antd/dist/antd.min.css';

moment.locale('es');
const authAPI = FincaAPI.withAuthUser(GENERAL.LOCAL_TOKEN.get());
const queryClient = new QueryClient();

const App = () => {
  return (
    <ConfigProvider locale={esES}>
      <QueryClientProvider client={queryClient}>
        <APIProvider value={{ api: authAPI }}>
          <ThemeSelector>
            <Switch>
              <Route exact path="/login" component={Login} />
              <PrivateRoute
                path="/admin"
                component={RoutesAdmin}
                allowedRoles={[USERS.ROLES.ADMIN_USER]}
              />
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute
                exact
                path="/perfil"
                component={Profile}
                allowedRoles={[USERS.ROLES.FINCA_USER]}
              />
              <PrivateRoute
                exact
                path="/sincronizacion"
                component={EventSync}
                allowedRoles={[USERS.ROLES.FINCA_USER]}
              />
              <PrivateRoute
                exact
                path="/notificaciones"
                component={Notifications}
                allowedRoles={[USERS.ROLES.FINCA_USER]}
              />
              <PrivateRoute
                path={`/${ESTABLISHMENT.NAME_SPACE}`}
                component={RoutesEstablishment}
              />
              <PrivateRoute
                path={`/${UNITS.NAME_SPACE}`}
                component={RoutesUnits}
              />
              <PrivateRoute
                path={`/${MANAGEMENT.NAME_SPACE}`}
                component={RoutesManagement}
              />
              <PrivateRoute path="/analiticas" component={RoutesAnalytics} />
              {/* Paginas de estados / errores */}
              <Route component={() => <h1>Finca - Content not found</h1>} />
            </Switch>
          </ThemeSelector>
        </APIProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default App;
