import React, { useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Collapse,
  Slider,
} from 'antd';
import { GENERAL, MASTERS, UNITS } from 'modules/core/constants';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';

const { Panel } = Collapse;
const { Item } = Form;
const { RangePicker } = DatePicker;

const PARAMS = {
  RP: 'rp',
  ID_ELECTRONICO: 'id_electronico',
  NRO_TRAZABILIDAD: 'nro_trazabilidad',
  APODO: 'apodo',
  NOMBRE: 'nombre',
  CATEGORIA: 'categoria',
  ORIGEN: 'origen',
  RAZA: 'raza',
  REGISTRO: 'registro',
  COLOR: 'color',
  ESTADO: 'estado',
  MADRE: 'madre',
  PADRE: 'padre',
  UBICACION_GEOGRAFICA: 'ubicacion_geografica',
  CRIADOR: 'criador',
  PROPIETARIO: 'propietario',
  RODEO: 'rodeo',
  HBA: 'hba',
  ID_ASOCIACION: 'id_asociacion',
  CUT: 'cut',
  FECHA_NACIMIENTO: `${GENERAL.FILTER_RANGE}fecha_nacimiento`,
  PESO_ACTUAL: `${GENERAL.FILTER_RANGE}peso_actual`,
  CONDICION_CORPORAL: `${GENERAL.FILTER_RANGE}condicion_corporal`,
  CANTIDAD_PARTOS: `${GENERAL.FILTER_RANGE}cantidad_partos`,
  CIRCUNFERENCIA: `${GENERAL.FILTER_RANGE}circunferencia_escrotal`,
};

const tags = {
  [PARAMS.RP]: 'RP',
  [PARAMS.ID_ELECTRONICO]: 'ID Electrónico',
  [PARAMS.NRO_TRAZABILIDAD]: 'Nro. Trazabilidad',
  [PARAMS.APODO]: 'Apodo',
  [PARAMS.NOMBRE]: 'Nombre',
  [PARAMS.CATEGORIA]: 'Categoría',
  [PARAMS.ORIGEN]: 'Origen',
  [PARAMS.RAZA]: 'Raza',
  [PARAMS.REGISTRO]: 'Registro',
  [PARAMS.COLOR]: 'Color',
  [PARAMS.ESTADO]: 'Estado',
  [PARAMS.MADRE]: 'Madre',
  [PARAMS.PADRE]: 'Padre',
  [PARAMS.UBICACION_GEOGRAFICA]: 'Ubicación',
  [PARAMS.CRIADOR]: 'Criador',
  [PARAMS.PROPIETARIO]: 'Propietario',
  [PARAMS.RODEO]: 'Rodeo',
  [PARAMS.HBA]: 'HBA',
  [PARAMS.ID_ASOCIACION]: 'Identificación asociación',
  [PARAMS.CUT]: 'CUT',
  [PARAMS.FECHA_NACIMIENTO]: 'Fecha nacimiento',
  [PARAMS.PESO_ACTUAL]: 'Peso (kg)',
  [PARAMS.CONDICION_CORPORAL]: 'Condición corporal',
  [PARAMS.CANTIDAD_PARTOS]: 'Cantidad partos',
  [PARAMS.CIRCUNFERENCIA]: 'Circunferencia escrotal',
};

const ranges = {
  [PARAMS.PESO_ACTUAL]: [0, 2000],
  [PARAMS.CONDICION_CORPORAL]: [0, 10],
  [PARAMS.CANTIDAD_PARTOS]: [0, 20],
  [PARAMS.CIRCUNFERENCIA]: [0, 100],
};

const isFromType = (unitType, type) =>
  Array.isArray(unitType) ? unitType.includes(type) : unitType === type;

const FormFields = ({ api, form, unitType = UNITS.HEMBRAS }) => {
  const queryOptions = { staleTime: 100000 };
  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'MAESTROS::fetchMastersAsFormOptions',
      {
        masterTypes: [
          MASTERS.PRODUCTIVA_TYPES.ORIGEN,
          MASTERS.PRODUCTIVA_TYPES.RAZA,
          MASTERS.PRODUCTIVA_TYPES.REGISTRO,
          MASTERS.PRODUCTIVA_TYPES.COLOR,
          MASTERS.PRODUCTIVA_TYPES.UBICACION,
          MASTERS.PRODUCTIVA_TYPES.CRIADOR,
          MASTERS.PRODUCTIVA_TYPES.RODEO,
        ],
        paramsByType: {
          [MASTERS.PRODUCTIVA_TYPES.UBICACION]: { is_padre: false },
        },
      },
    ],
    api.fetchMastersFormOptions,
    queryOptions
  );
  const { data: females, isLoading: isLoadingFemales } = useQuery(
    ['Units::females', { params: { sexo: UNITS.HEMBRAS } }],
    api.fetchUnits,
    queryOptions
  );
  const { data: males, isLoading: isLoadingMales } = useQuery(
    ['Units::males', { params: { sexo: UNITS.MACHOS } }],
    api.fetchUnits,
    queryOptions
  );
  const [breed, setBreed] = useState(null);
  const onChangeBreed = (breed) => {
    setBreed(breed?.value ?? null);
    form.setFieldsValue({ registro: undefined, color: undefined });
  };

  return (
    <Row gutter={0}>
      <Col span={24}>
        <Item label={tags[PARAMS.CATEGORIA]} name={PARAMS.CATEGORIA}>
          <Select
            placeholder="Seleccionar valor"
            mode="multiple"
            showArrow
            allowClear
            labelInValue={true}
            dropdownMatchSelectWidth={200}
            options={UNITS.unitOptionsByType(api.userSpecies)}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item label={tags[PARAMS.ESTADO]} name={PARAMS.ESTADO}>
          <Select
            placeholder="Seleccionar valor"
            mode="multiple"
            showArrow
            allowClear
            labelInValue={true}
            dropdownMatchSelectWidth={200}
            disabled={!isFromType(unitType, UNITS.HEMBRAS)}
            options={GENERAL.arrayToOptions(
              UNITS.ESTADOS_LIST,
              (s) => UNITS.ESTADOS_FORMATTED[s]
            )}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item label={tags[PARAMS.RODEO]} name={PARAMS.RODEO}>
          <Select
            placeholder="Seleccionar valor"
            mode="multiple"
            showArrow
            allowClear
            labelInValue={true}
            dropdownMatchSelectWidth={200}
            loading={isLoadingOptions}
            options={options?.[MASTERS.PRODUCTIVA_TYPES.RODEO]}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Item
          label={tags[PARAMS.UBICACION_GEOGRAFICA]}
          name={PARAMS.UBICACION_GEOGRAFICA}
        >
          <Select
            placeholder="Seleccionar valor"
            mode="multiple"
            showArrow
            allowClear
            labelInValue={true}
            dropdownMatchSelectWidth={200}
            loading={isLoadingOptions}
            options={options?.[MASTERS.PRODUCTIVA_TYPES.UBICACION]}
          />
        </Item>
      </Col>
      <Col span={24}>
        <Collapse ghost>
          <Panel header="Filtros de Unidad" key="filtrosUnidad">
            <Row gutter={8}>
              <Col span={24}>
                <Item label={tags[PARAMS.RP]} name={PARAMS.RP}>
                  <Input placeholder="Ingresar valor" />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.ID_ELECTRONICO]}
                  name={PARAMS.ID_ELECTRONICO}
                >
                  <Input placeholder="Ingresar valor" />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.NRO_TRAZABILIDAD]}
                  name={PARAMS.NRO_TRAZABILIDAD}
                >
                  <Input placeholder="Ingresar valor" />
                </Item>
              </Col>
              <Col span={24}>
                <Item label={tags[PARAMS.APODO]} name={PARAMS.APODO}>
                  <Input placeholder="Ingresar valor" />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.FECHA_NACIMIENTO]}
                  name={PARAMS.FECHA_NACIMIENTO}
                >
                  <RangePicker style={{ width: '100%' }} />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.PESO_ACTUAL]}
                  name={PARAMS.PESO_ACTUAL}
                >
                  <Slider
                    {...GENERAL.generateSliderProps(ranges[PARAMS.PESO_ACTUAL])}
                  />
                </Item>
              </Col>
            </Row>
          </Panel>

          <Panel header="Filtros de Origen" key="filtrosOrigen">
            <Row gutter={8}>
              <Col span={24}>
                <Item label={tags[PARAMS.ORIGEN]} name={PARAMS.ORIGEN}>
                  <Select
                    placeholder="Seleccionar valor"
                    mode="multiple"
                    showArrow
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.ORIGEN]}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item label={tags[PARAMS.MADRE]} name={PARAMS.MADRE}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    showSearch
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    optionFilterProp="label"
                    loading={isLoadingFemales}
                    options={GENERAL.toRPOptions(females?.results, {
                      value: 'id',
                    })}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item label={tags[PARAMS.PADRE]} name={PARAMS.PADRE}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    showSearch
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    optionFilterProp="label"
                    loading={isLoadingMales}
                    options={GENERAL.toRPApodoOptions(males?.results)}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item label={tags[PARAMS.CRIADOR]} name={PARAMS.CRIADOR}>
                  <Select
                    placeholder="Seleccionar valor"
                    mode="multiple"
                    showArrow
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.CRIADOR]}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.PROPIETARIO]}
                  name={PARAMS.PROPIETARIO}
                >
                  <Select
                    placeholder="Seleccionar valor"
                    mode="multiple"
                    showArrow
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.CRIADOR]}
                  />
                </Item>
              </Col>
            </Row>
          </Panel>

          <Panel header="Filtros de Raza" key="filtrosRaza">
            <Row gutter={8}>
              <Col span={24}>
                <Item label={tags[PARAMS.RAZA]} name={PARAMS.RAZA}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.RAZA]}
                    onChange={onChangeBreed}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item label={tags[PARAMS.REGISTRO]} name={PARAMS.REGISTRO}>
                  <Select
                    placeholder="Seleccionar valor"
                    mode="multiple"
                    showArrow
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[
                      MASTERS.PRODUCTIVA_TYPES.REGISTRO
                    ].filter((o) => o.raza === breed)}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item label={tags[PARAMS.COLOR]} name={PARAMS.COLOR}>
                  <Select
                    placeholder="Seleccionar valor"
                    mode="multiple"
                    showArrow
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.COLOR].filter(
                      (o) => o.raza === breed
                    )}
                  />
                </Item>
              </Col>
            </Row>
          </Panel>

          <Panel header="Otros filtros" key="filtrosOtros">
            <Row gutter={8}>
              <Col span={24}>
                <Item label={tags[PARAMS.HBA]} name={PARAMS.HBA}>
                  <Input placeholder="Ingresar valor" />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.ID_ASOCIACION]}
                  name={PARAMS.ID_ASOCIACION}
                >
                  <Input placeholder="Ingresar valor" />
                </Item>
              </Col>
              <Col span={24}>
                <Item label={tags[PARAMS.NOMBRE]} name={PARAMS.NOMBRE}>
                  <Input placeholder="Ingresar valor" />
                </Item>
              </Col>
              {isFromType(unitType, UNITS.HEMBRAS) && (
                <>
                  <Col span={24}>
                    <Form.Item label={tags[PARAMS.CUT]} name={PARAMS.CUT}>
                      <Select
                        placeholder="Seleccionar valor"
                        allowClear
                        labelInValue={true}
                        dropdownMatchSelectWidth={200}
                        options={MASTERS.BOOLEAN_OPTIONS}
                        disabled={!isFromType(unitType, UNITS.HEMBRAS)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Item
                      label={tags[PARAMS.CONDICION_CORPORAL]}
                      name={PARAMS.CONDICION_CORPORAL}
                    >
                      <Slider
                        {...GENERAL.generateSliderProps(
                          ranges[PARAMS.CONDICION_CORPORAL]
                        )}
                        disabled={!isFromType(unitType, UNITS.HEMBRAS)}
                      />
                    </Item>
                  </Col>
                  <Col span={24}>
                    <Item
                      label={tags[PARAMS.CANTIDAD_PARTOS]}
                      name={PARAMS.CANTIDAD_PARTOS}
                    >
                      <Slider
                        {...GENERAL.generateSliderProps(
                          ranges[PARAMS.CANTIDAD_PARTOS]
                        )}
                        disabled={!isFromType(unitType, UNITS.HEMBRAS)}
                      />
                    </Item>
                  </Col>
                </>
              )}
              {isFromType(unitType, UNITS.MACHOS) && (
                <Col span={24}>
                  <Item
                    label={tags[PARAMS.CIRCUNFERENCIA]}
                    name={PARAMS.CIRCUNFERENCIA}
                  >
                    <Slider
                      {...GENERAL.generateSliderProps(
                        ranges[PARAMS.CIRCUNFERENCIA]
                      )}
                      disabled={!isFromType(unitType, UNITS.MACHOS)}
                    />
                  </Item>
                </Col>
              )}
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
