import React from 'react';
import { Form, Row, Col, Collapse, DatePicker, Select } from 'antd';
import { GENERAL, MASTERS } from 'modules/core/constants';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';

const { Panel } = Collapse;
const { Item } = Form;
const { RangePicker } = DatePicker;

const PARAMS = {
  FECHA_EVENTO: `${GENERAL.FILTER_RANGE}fecha_evento`,
  BAJA_MOTIVO: 'motivo',
};

const tags = {
  [PARAMS.FECHA_EVENTO]: 'Fecha baja',
  [PARAMS.BAJA_MOTIVO]: 'Motivo',
};

const FormFields = ({ api }) => {
  const queryOptions = { staleTime: 100000 };
  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'MAESTROS::fetchMastersAsFormOptions',
      {
        masterTypes: [MASTERS.PRODUCTIVA_TYPES.MOTIVO],
      },
    ],
    api.fetchMastersFormOptions,
    queryOptions
  );

  return (
    <Row gutter={[0]}>
      <Col span={24}>
        <Collapse ghost>
          <Panel header="Baja" key="baja">
            <Row gutter={8}>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.FECHA_EVENTO]}
                  name={PARAMS.FECHA_EVENTO}
                >
                  <RangePicker style={{ width: '100%' }} />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.BAJA_MOTIVO]}
                  name={PARAMS.BAJA_MOTIVO}
                >
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.MOTIVO]}
                  />
                </Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
