import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Row, Col, message } from 'antd';
import { LoginForm } from './components';
import { withAPI } from 'modules/api';
import { GENERAL } from 'modules/core/constants';
import logoFinca from 'assets/login/logo_finca.svg';
import logoOvinos from 'assets/login/logo_ovinos.svg';
import logoBovinos from 'assets/login/logo_bovinos.svg';
import bovinos1 from 'assets/login/bovinos_1.svg';
import bovinos2 from 'assets/login/bovinos_2.svg';
import bovinos3 from 'assets/login/bovinos_3.svg';
import ovinos1 from 'assets/login/ovinos_1.svg';
import ovinos2 from 'assets/login/ovinos_2.svg';
import ovinos3 from 'assets/login/ovinos_3.svg';

import './Login.scss';

const Login = ({ api, logUser }) => {
  const history = useHistory();
  if (api.authToken) history.push('/');

  const { data: version } = useQuery('Auth::fetchVersion', api.fetchVersion);

  const appVersion = version?.version ? `• v${version?.version}` : '';

  const onSuccessfulLogin = ({ access: token }) => {
    logUser(token);
    GENERAL.LOCAL_TOKEN.set(token);
    history.push('/');
  };

  const onError = ({ message: error }) => {
    console.log('ERROR', error);
    switch (error) {
      case '401':
        message.error('Credenciales inválidas', 5);
        break;
      case '400':
        message.error(
          'No existe una licencia activa para el establecimiento.',
          5
        );
        break;
      default:
        message.error('Error al conectarse al servidor');
    }
  };

  const { mutate: login, isLoading } = useMutation(api.login, {
    onSuccess: onSuccessfulLogin,
    onError: onError,
  });

  const renderSiderIcons = ({ style = {}, logo, icons }) => (
    <>
      <Col span={6} className="login-logo-column">
        <img src={logo} alt="Logo especie" height={380} />
      </Col>
      <div style={{ position: 'absolute', bottom: 8, ...style }}>
        {icons.map((i) => (
          <img key={i} src={i} alt="icon" height={80} />
        ))}
      </div>
    </>
  );

  return (
    <>
      <Row className="login-container">
        {renderSiderIcons({
          style: { left: 16 },
          logo: logoBovinos,
          icons: [bovinos1, bovinos2, bovinos3],
        })}
        <Col span={10} className="login-middle-column">
          <div className="login-content">
            <img src={logoFinca} alt="Logo Finca" height={120} />
            <div className="login-content-description">
              Ingresá tu e-mail y tu contraseña para comenzar.
            </div>
            <LoginForm onSubmit={login} loading={isLoading} />
          </div>
        </Col>
        {renderSiderIcons({
          style: { right: 16 },
          logo: logoOvinos,
          icons: [ovinos1, ovinos2, ovinos3],
        })}
        <div className="login-content-elements-version">
          {`Finca ${moment().year()} ${appVersion}`}
        </div>
      </Row>
    </>
  );
};

export default withAPI(Login);
