import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import moment from 'moment';
import _ from 'lodash';
import { Spin, Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as ArrendamientoIcon } from 'assets/market/arrendamiento.svg';
import { ReactComponent as DolarIcon } from 'assets/market/dolar.svg';
import { ReactComponent as MaizIcon } from 'assets/market/maiz.svg';
import { ReactComponent as SojaIcon } from 'assets/market/soja.svg';
import { ReactComponent as TrigoIcon } from 'assets/market/trigo.svg';
import { GENERAL } from 'modules/core/constants';

import './TopBarInfo.scss';

const MARKET_INFO = {
  arrendamiento: {
    Icon: ArrendamientoIcon,
    tooltip: 'Precio Novillo de Arrendamiento (ARS/Kg)',
  },
  dolar: {
    Icon: DolarIcon,
    tooltip: 'USD Oficial Venta Banco Nación (ARS/USD)',
  },
  maiz: { Icon: MaizIcon, tooltip: 'Precio internacional del Maiz (USD/Ton)' },
  soja: {
    Icon: SojaIcon,
    tooltip: 'Precio internacional de la Soja (USD/Ton)',
  },
  trigo: {
    Icon: TrigoIcon,
    tooltip: 'Precio internacional del Trigo (USD/Ton)',
  },
};

const TopBarInfo = ({ api, lat, lon }) => {
  const fullDate = moment().format('dddd DD [de] MMMM [de] YYYY');
  const fullDateFormatted =
    fullDate.charAt(0).toUpperCase() + fullDate.slice(1);

  const { data: weather, error } = useQuery(
    ['Home::Weather', { lat, lon }],
    api.fetchWeather,
    {
      enabled: !!(lat && lon),
    }
  );

  const { data: marketData, isLoading: isLoadingMarketData } = useQuery(
    ['Home::fetchMarketData'],
    api.fetchMarketData
  );

  const renderWeather = () => (
    <Space>
      {fullDateFormatted}
      {!error && (
        <>
          <img
            src={`http://openweathermap.org/img/wn/${weather?.current.weather[0].icon}.png`}
            alt="weather"
            height="45"
          />
          <span>
            {`${Math.round(weather?.daily[0].temp.max)}º`}
            {`/${Math.round(weather?.daily[0].temp.min)}º`}
          </span>
          <Tooltip
            title={
              <Space direction="vertical">
                {`Humedad: ${weather?.current.humidity}%`}
                {`Prob. precipitaciones: ${GENERAL.roundPercentage(
                  weather?.daily[0].pop
                )}`}
              </Space>
            }
          >
            <InfoCircleOutlined />
          </Tooltip>
        </>
      )}
    </Space>
  );

  const renderMarketData = () => (
    <Space size="middle" className="market-data">
      <span>Mercado</span>
      {_.map(marketData, (d, k) => {
        const Icon = MARKET_INFO[k].Icon;
        return (
          <div key={k} className="market-data-value-container">
            <Tooltip title={MARKET_INFO[k].tooltip}>
              <Icon className="market-data-icon" />
              {`$${d ? d : '-'}`}
            </Tooltip>
          </div>
        );
      })}
    </Space>
  );

  if (!weather || isLoadingMarketData) return <Spin />;

  return (
    <Space className="dashboard-top-bar-info">
      {renderWeather()}
      {renderMarketData()}
    </Space>
  );
};

export default withAPI(TopBarInfo);
