import React from 'react';
import { Form, Row, Col, Collapse, InputNumber, Select } from 'antd';
import { GENERAL, MASTERS } from 'modules/core/constants';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';

const { Panel } = Collapse;
const { Item } = Form;

const PARAMS = {
  DEP: 'dep',
  VALUE_FROM: 'from_valor',
  VALUE_TO: 'to_valor',
  BAJA: 'baja',
};

const tags = {
  [PARAMS.DEP]: 'DEP',
  [PARAMS.VALUE_FROM]: 'Valor desde',
  [PARAMS.VALUE_TO]: 'Valor hasta',
  [PARAMS.BAJA]: 'Estado de unidad',
};

const FormFields = ({ api }) => {
  const queryOptions = { staleTime: 100000 };
  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'MAESTROS::fetchMastersAsFormOptions',
      {
        masterTypes: [MASTERS.PRODUCTIVA_TYPES.DEPS],
      },
    ],
    api.fetchMastersFormOptions,
    queryOptions
  );

  return (
    <Row gutter={[0]}>
      <Col span={24}>
        <Collapse ghost>
          <Panel header="DEPs" key="deps">
            <Row gutter={8}>
              <Col span={24}>
                <Item label={tags[PARAMS.DEP]} name={PARAMS.DEP}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.DEPS]}
                  />
                </Item>
              </Col>
              <Col span={12}>
                <Item label={tags[PARAMS.VALUE_FROM]} name={PARAMS.VALUE_FROM}>
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Ingresar valor"
                  />
                </Item>
              </Col>
              <Col span={12}>
                <Item label={tags[PARAMS.VALUE_TO]} name={PARAMS.VALUE_TO}>
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder="Ingresar valor"
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Form.Item label={tags[PARAMS.BAJA]} name={PARAMS.BAJA}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    options={GENERAL.optionsBoolToStr(MASTERS.BAJA_OPTIONS)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
