import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { withAPI } from 'modules/api';
import { saveAs } from 'file-saver';
import { EllipsisOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { UNITS } from 'modules/core/constants';
import { Container, DropdownMenu, Table } from 'modules/core/components';
import FilterSider, { UnitFilter } from 'modules/core/components/Filter';

const UnitRankingAnalytics = ({ api, isActive, unitType }) => {
  const [isExporting, setIsExporting] = useState(false);
  const { pagination, filters, sorter, params } = usePaginationWithFilters();
  const { data: unitsRanking, isLoading: isLoadingRanking } = useQuery(
    ['Analytics::fetchUnitRanking', { unitType, params }],
    api.fetchUnitRanking,
    { enabled: isActive }
  );

  const { mutate: exportUnitRanking } = useMutation(api.exportUnitRanking);
  const onExportUnitRanking = () => {
    const filename = unitType === UNITS.MACHOS ? 'Top machos' : 'Top hembras';
    setIsExporting(true);
    exportUnitRanking(
      { unitType },
      {
        onSuccess: (response) => {
          saveAs(response, `Analíticas unidades - ${filename}.xlsx`);
          setIsExporting(false);
        },
      }
    );
  };

  const unitColumns = [
    { title: 'Partos', dataIndex: 'cant_partos', sorter: true },
    { title: 'GDP al destete', dataIndex: 'gdp_destete', sorter: true },
  ];
  const femaleColumns = [
    { title: 'Hembras', dataIndex: 'rp', sorter: true },
    ...unitColumns,
    { title: 'Coef de preñez', dataIndex: 'coef_prenez', sorter: true },
    { title: 'IEP', dataIndex: 'iep', sorter: true },
  ];
  const maleColumns = [
    { title: 'Machos', dataIndex: 'rp', sorter: true },
    ...unitColumns,
    {
      title: 'Peso al nacimiento',
      dataIndex: 'avg_peso_nacimiento',
      sorter: true,
    },
  ];

  const columns = unitType === UNITS.HEMBRAS ? femaleColumns : maleColumns;

  return (
    <>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <Container>
            <FilterSider
              showDrawer
              showCurrentYear
              showGlobalSearch={false}
              filters={[UnitFilter]}
              filtersProps={{ unitType: unitType }}
              onChange={filters.set}
            />
            <DropdownMenu
              menu={[
                {
                  title: 'Exportar a Excel',
                  icon: <DownloadOutlined />,
                  onClick: () => onExportUnitRanking(),
                },
              ]}
            >
              <Button icon={<EllipsisOutlined />} />
            </DropdownMenu>
          </Container>
        </Col>
        <Col span={24}>
          <Table
            rowKey="rp"
            dataSource={unitsRanking?.results}
            columns={columns}
            loading={isLoadingRanking || isExporting}
            showTotal={true}
            pagination={{ ...pagination.get(), total: unitsRanking?.count }}
            onChange={(p, f, s) => {
              pagination.set(p);
              sorter.set(s);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default withAPI(UnitRankingAnalytics);
