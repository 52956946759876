import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { saveAs } from 'file-saver';
import { withAPI } from 'modules/api';
import { GENERAL, NUTRICIONAL, ESTABLISHMENT } from 'modules/core/constants';
import { Button } from 'antd';
import {
  EllipsisOutlined,
  DownloadOutlined,
  FileAddOutlined,
  RollbackOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  ExportFieldsModal,
  UploadModal,
  DropdownMenu,
} from 'modules/core/components';
import { getFileAffix, getExportFns } from './utils/RecordsMenuHelpers';

const RecordsMenu = ({
  api,
  disabled,
  recordType,
  exportParams,
  onUpload,
  onUndo,
  withoutExportFields = false,
}) => {
  const fileAffix = getFileAffix(recordType);
  const { mutate: exportEvent } = useMutation(
    getExportFns(recordType, api)?.excel
  );
  const [viewExport, setViewExport] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [viewUpload, setViewUpload] = useState(false);

  const { data: availableFields } = useQuery(
    ['Events::fieldsExportEvent', { eventType: recordType }],
    getExportFns(recordType, api)?.fields,
    { enabled: !withoutExportFields && viewExport }
  );

  const onExportEventsRecords = (fields) => {
    setIsExporting(true);
    exportEvent(
      {
        eventType: recordType,
        fields,
        params: exportParams,
        withoutExportFields: withoutExportFields,
      },
      {
        onSuccess: (response) => {
          saveAs(response, `${fileAffix}-Exportados.xlsx`);
          setIsExporting(false);
          setViewExport(false);
        },
      }
    );
  };

  const handleOnExportMenu = () => {
    if (withoutExportFields) {
      return onExportEventsRecords();
    }
    return setViewExport(true);
  };

  const getOptions = () => {
    const exportOption = {
      title: 'Exportar a excel',
      icon: <DownloadOutlined />,
      onClick: () => handleOnExportMenu(),
    };
    const undoOption = {
      title: 'Deshacer',
      icon: <RollbackOutlined />,
      onClick: () => onUndo(true),
    };

    const eventDefaultOptions = [exportOption, undoOption];

    if (recordType === NUTRICIONAL.DIETA)
      return [
        {
          title: 'Finalización masiva',
          icon: <FileAddOutlined />,
          onClick: () => setViewUpload(true),
        },
      ].concat(eventDefaultOptions);

    if (ESTABLISHMENT.MOVIMIENTOS_LIST.includes(recordType))
      return [undoOption];

    return eventDefaultOptions;
  };

  return (
    <>
      <DropdownMenu disabled={disabled} menu={getOptions()}>
        <Button
          icon={isExporting ? <LoadingOutlined /> : <EllipsisOutlined />}
        />
      </DropdownMenu>
      <ExportFieldsModal
        visible={viewExport}
        fields={availableFields}
        loading={isExporting}
        onSuccess={onExportEventsRecords}
        close={() => setViewExport(false)}
      />
      {recordType === NUTRICIONAL.DIETA && (
        <UploadModal
          title={fileAffix}
          apiFn={api.uploadNutricionalEvent}
          params={{
            eventType: recordType,
            params: { finalizar: GENERAL.valueBoolToStr(true) },
          }}
          visible={viewUpload}
          close={() => setViewUpload(false)}
          onUpload={onUpload}
        />
      )}
    </>
  );
};

export default withAPI(RecordsMenu);
