import { UNITS } from '.';
const NAME_SPACE = 'productiva';

// EVENTS
const GENERIC_EVENT = 'eventos';
const SERVICIOS_NAME_SPACE = 'servicios';

const BAJA = 'baja';
const MOVIMIENTO = 'movimiento';
const DESTETE = 'destete';
const TACTO = 'tacto';
const ASIGNACION = 'asignacion';
const SERVICIO = 'servicio';
const SERVICIOCOLECTIVO = 'serviciocolectivo';
const TRANSFERENCIAEMBRIONARIA = 'transferenciaembrionaria';
const PRENIEZ = 'prenez';
const PARTO = 'parto';
const PESAJE = 'pesaje';
const SELECCION_HEMBRAS = 'seleccionhembras';
const SELECCION_MACHOS = 'seleccion';
const DATOS_ADICIONALES = 'datoadicional';
const DEPS = 'dep';
// OVINOS
const ESQUILA = 'esquila';

const BOVINO_EVENT_TYPES = [
  BAJA,
  MOVIMIENTO,
  DESTETE,
  TACTO,
  ASIGNACION,
  SERVICIO,
  SERVICIOCOLECTIVO,
  TRANSFERENCIAEMBRIONARIA,
  PRENIEZ,
  PARTO,
  PESAJE,
  SELECCION_HEMBRAS,
  SELECCION_MACHOS,
  DATOS_ADICIONALES,
  DEPS,
];

const OVINO_EVENT_TYPES = [...BOVINO_EVENT_TYPES, ESQUILA];

const EVENT_TYPES = [...new Set([...BOVINO_EVENT_TYPES, ...OVINO_EVENT_TYPES])];
const EVENT_TYPES_UNIT_ROUTES = [BAJA, MOVIMIENTO];

const OPTIONS = {
  [TACTO]: [
    { value: true, label: 'Apta' },
    { value: false, label: 'No apta' },
  ],
  [PRENIEZ]: {
    STATUS: [
      { value: true, label: 'Preñada' },
      { value: false, label: 'Vacía' },
    ],
    TYPE: [
      { value: 'chico', label: 'Chico' },
      { value: 'grande', label: 'Grande' },
    ],
  },
  [SERVICIO]: [
    { value: 'natural', label: 'Natural' },
    { value: 'iatf', label: 'IATF' },
    { value: 'ia', label: 'IA' },
    { value: 'resincro', label: 'Resincro' },
  ],
  [TRANSFERENCIAEMBRIONARIA]: [
    { value: 'fiv', label: 'FIV' },
    { value: 'en_fresco', label: 'En fresco' },
  ],
};

const EVENT_FORMATTED = {
  [BAJA]: 'Bajas',
  [MOVIMIENTO]: 'Movimientos',
  [DESTETE]: 'Destete',
  [TACTO]: 'Pre-Servicio',
  [ASIGNACION]: 'Asignación',
  [SERVICIO]: 'Servicio individual',
  [SERVICIOCOLECTIVO]: 'Servicio colectivo',
  [TRANSFERENCIAEMBRIONARIA]: 'Transferencia embrionaria',
  [PRENIEZ]: 'Preñez',
  [PARTO]: 'Parto',
  [PESAJE]: 'Pesaje',
  [SELECCION_HEMBRAS]: 'Selección de hembras',
  [SELECCION_MACHOS]: 'Selección de machos',
  [DATOS_ADICIONALES]: 'Datos adicionales',
  [DEPS]: 'DEPs',
  [ESQUILA]: 'Esquila',
};

const BOVINO_EVENT_DASHBOARD = {
  [DESTETE]: {
    title: EVENT_FORMATTED[DESTETE],
    description: 'unidades disponibles',
    url: DESTETE,
  },
  [TACTO]: {
    title: EVENT_FORMATTED[TACTO],
    description: 'unidades disponibles',
    url: TACTO,
  },
  [ASIGNACION]: {
    title: EVENT_FORMATTED[ASIGNACION],
    description: 'unidades disponibles',
    url: ASIGNACION,
  },
  [SERVICIOCOLECTIVO]: {
    title: 'Servicio',
    description: 'unidades disponibles',
    url: SERVICIOS_NAME_SPACE,
  },
  [PRENIEZ]: {
    title: EVENT_FORMATTED[PRENIEZ],
    description: 'unidades disponibles',
    url: PRENIEZ,
  },
  [PARTO]: {
    title: EVENT_FORMATTED[PARTO],
    description: 'unidades disponibles',
    url: PARTO,
  },
  [PESAJE]: {
    title: EVENT_FORMATTED[PESAJE],
    description: 'unidades disponibles',
    url: PESAJE,
  },
  [SELECCION_HEMBRAS]: {
    title: EVENT_FORMATTED[SELECCION_HEMBRAS],
    description: 'unidades disponibles',
    url: SELECCION_HEMBRAS,
  },
  [SELECCION_MACHOS]: {
    title: EVENT_FORMATTED[SELECCION_MACHOS],
    description: 'unidades disponibles',
    url: SELECCION_MACHOS,
  },
  [DATOS_ADICIONALES]: {
    title: EVENT_FORMATTED[DATOS_ADICIONALES],
    description: 'unidades disponibles',
    url: DATOS_ADICIONALES,
  },
  [DEPS]: {
    title: EVENT_FORMATTED[DEPS],
    description: 'unidades disponibles',
    url: DEPS,
  },
};

const OVINO_EVENT_DASHBOARD = {
  ...BOVINO_EVENT_DASHBOARD,
  [ESQUILA]: {
    title: EVENT_FORMATTED[ESQUILA],
    description: 'unidades disponibles',
    url: ESQUILA,
  },
};

const SERVICIOS = {
  NAME_SPACE: SERVICIOS_NAME_SPACE,
  EVENT_TYPES: [SERVICIO, SERVICIOCOLECTIVO, TRANSFERENCIAEMBRIONARIA],
  EVENT_DASHBOARD: {
    [SERVICIO]: {
      title: EVENT_FORMATTED[SERVICIO],
      description: 'unidades disponibles',
      url: SERVICIO,
    },
    [SERVICIOCOLECTIVO]: {
      title: EVENT_FORMATTED[SERVICIOCOLECTIVO],
      description: 'unidades disponibles',
      url: SERVICIOCOLECTIVO,
    },
    [TRANSFERENCIAEMBRIONARIA]: {
      title: EVENT_FORMATTED[TRANSFERENCIAEMBRIONARIA],
      description: 'embriones disponibles',
      url: TRANSFERENCIAEMBRIONARIA,
    },
  },
};

const EVENT_UNIT_TYPES = {
  [BAJA]: [UNITS.HEMBRAS, UNITS.MACHOS],
  [MOVIMIENTO]: [UNITS.HEMBRAS, UNITS.MACHOS],
  [DESTETE]: [UNITS.HEMBRAS, UNITS.MACHOS],
  [TACTO]: [UNITS.HEMBRAS],
  [ASIGNACION]: [UNITS.HEMBRAS],
  [SERVICIO]: [UNITS.HEMBRAS],
  [SERVICIOCOLECTIVO]: [UNITS.HEMBRAS],
  [TRANSFERENCIAEMBRIONARIA]: [UNITS.HEMBRAS],
  [PRENIEZ]: [UNITS.HEMBRAS],
  [PARTO]: [UNITS.HEMBRAS],
  [PESAJE]: [UNITS.HEMBRAS, UNITS.MACHOS],
  [SELECCION_HEMBRAS]: [UNITS.HEMBRAS],
  [SELECCION_MACHOS]: [UNITS.MACHOS],
  [DEPS]: [UNITS.HEMBRAS, UNITS.MACHOS],
  [ESQUILA]: [UNITS.HEMBRAS, UNITS.MACHOS],
};

// NOEVENTS
const SEMEN = 'semen';
const EMBRION = 'embrion';
const MOTIVOS_BAJA = 'motivosbaja';

const NOEVENT_TYPES = { SEMEN, EMBRION, MOTIVOS_BAJA };
const NOEVENT_TYPES_LIST = Object.values(NOEVENT_TYPES);

const NOEVENT_FORMATTED = {
  [SEMEN]: 'Semen',
  [EMBRION]: 'Embriones',
};

const NOEVENTS_DASHBOARD_INSUMOS = {
  [SEMEN]: {
    title: NOEVENT_FORMATTED[SEMEN],
    url: SEMEN,
  },
  [EMBRION]: {
    title: NOEVENT_FORMATTED[EMBRION],
    url: EMBRION,
  },
};

const NOEVENTS_DASHBOARD_CONFIG = {
  [MOTIVOS_BAJA]: {
    title: 'Motivos de baja',
    url: MOTIVOS_BAJA,
  },
};

const PRODUCTIVA = {
  NAME_SPACE,
  GENERIC_EVENT,
  BAJA,
  MOVIMIENTO,
  DESTETE,
  TACTO,
  ASIGNACION,
  SERVICIO,
  SERVICIOCOLECTIVO,
  TRANSFERENCIAEMBRIONARIA,
  PRENIEZ,
  PARTO,
  PESAJE,
  SELECCION_HEMBRAS,
  SELECCION_MACHOS,
  DATOS_ADICIONALES,
  DEPS,
  ESQUILA,
  OPTIONS,
  BOVINO_EVENT_TYPES,
  OVINO_EVENT_TYPES,
  EVENT_TYPES,
  EVENT_TYPES_UNIT_ROUTES,
  EVENT_FORMATTED,
  BOVINO_EVENT_DASHBOARD,
  OVINO_EVENT_DASHBOARD,
  EVENT_UNIT_TYPES,
  SERVICIOS,
  SEMEN,
  EMBRION,
  MOTIVOS_BAJA,
  NOEVENT_TYPES,
  NOEVENT_TYPES_LIST,
  NOEVENT_FORMATTED,
  NOEVENTS_DASHBOARD_INSUMOS,
  NOEVENTS_DASHBOARD_CONFIG,
};

export default PRODUCTIVA;
