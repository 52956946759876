import React from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Collapse,
  DatePicker,
  Select,
  Slider,
} from 'antd';
import { GENERAL, MASTERS } from 'modules/core/constants';
import { withAPI } from 'modules/api';
import { useQuery } from 'react-query';

const { Panel } = Collapse;
const { Item } = Form;
const { RangePicker } = DatePicker;

const PARAMS = {
  FECHA_EVENTO: `${GENERAL.FILTER_RANGE}fecha_evento`,
  DESTETE_OBJETIVO: 'objetivo',
  PESO_DESTETE: `${GENERAL.FILTER_RANGE}peso_destete`,
  BAJA: 'baja',
  GANANCIA_DIARIA_FROM: 'from_ganancia_diaria',
  GANANCIA_DIARIA_TO: 'to_ganancia_diaria',
};

const tags = {
  [PARAMS.FECHA_EVENTO]: 'Fecha destete',
  [PARAMS.DESTETE_OBJETIVO]: 'Clasificación',
  [PARAMS.PESO_DESTETE]: 'Peso destete',
  [PARAMS.BAJA]: 'Estado de unidad',
  [PARAMS.GANANCIA_DIARIA_FROM]: 'Ganancia diaria desde',
  [PARAMS.GANANCIA_DIARIA_TO]: 'Ganancia diaria hasta',
};

const ranges = {
  [PARAMS.PESO_DESTETE]: [0, 2000],
};

const FormFields = ({ api, form }) => {
  const queryOptions = { staleTime: 100000 };
  const { data: options, isLoading: isLoadingOptions } = useQuery(
    [
      'MAESTROS::fetchMastersAsFormOptions',
      {
        masterTypes: [MASTERS.PRODUCTIVA_TYPES.DESTETE],
      },
    ],
    api.fetchMastersFormOptions,
    queryOptions
  );

  return (
    <Row gutter={[0]}>
      <Col span={24}>
        <Collapse ghost>
          <Panel header="Destete" key="destete">
            <Row gutter={8}>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.FECHA_EVENTO]}
                  name={PARAMS.FECHA_EVENTO}
                >
                  <RangePicker style={{ width: '100%' }} />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.DESTETE_OBJETIVO]}
                  name={PARAMS.DESTETE_OBJETIVO}
                >
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    loading={isLoadingOptions}
                    options={options?.[MASTERS.PRODUCTIVA_TYPES.DESTETE]}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Item
                  label={tags[PARAMS.PESO_DESTETE]}
                  name={PARAMS.PESO_DESTETE}
                >
                  <Slider
                    {...GENERAL.generateSliderProps(
                      ranges[PARAMS.PESO_DESTETE]
                    )}
                  />
                </Item>
              </Col>
              <Col span={24}>
                <Form.Item label={tags[PARAMS.BAJA]} name={PARAMS.BAJA}>
                  <Select
                    placeholder="Seleccionar valor"
                    allowClear
                    labelInValue={true}
                    dropdownMatchSelectWidth={200}
                    options={GENERAL.optionsBoolToStr(MASTERS.BAJA_OPTIONS)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Item
                  label={tags[PARAMS.GANANCIA_DIARIA_FROM]}
                  name={PARAMS.GANANCIA_DIARIA_FROM}
                >
                  <Input placeholder="Ingresar valor" />
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  label={tags[PARAMS.GANANCIA_DIARIA_TO]}
                  name={PARAMS.GANANCIA_DIARIA_TO}
                >
                  <Input placeholder="Ingresar valor" />
                </Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

const filterComponent = {
  tags,
  FormFields: withAPI(FormFields),
};

export default filterComponent;
