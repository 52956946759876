import React, { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useQuery, useMutation } from 'react-query';
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  InputNumber,
  Radio,
  DatePicker,
  Select,
  Spin,
  message as antMessage,
} from 'antd';
import { withAPI } from 'modules/api';
import { MASTERS, UNITS, GENERAL } from 'modules/core/constants';
import { displayInfoModal } from '../InfoModal/InfoModal';
import './UnitsAddForm.scss';

const { Item: FormItem } = Form;

const UnitsAddForm = ({
  api,
  visible = false,
  formAction = GENERAL.ACTIONS.CREAR,
  onCancel = null,
  onSuccess = null,
  initialValuesParto = null,
  initialValues = null,
}) => {
  const [form] = Form.useForm();
  const [unitType, setUnitType] = useState(null);
  const [breed, setBreed] = useState(null);

  const isFormEditing = formAction === GENERAL.ACTIONS.MODIFICAR;

  const { data: options } = useQuery(
    [
      'Masters::Bulk',
      {
        masterTypes: [
          MASTERS.PRODUCTIVA_TYPES.ORIGEN,
          MASTERS.PRODUCTIVA_TYPES.RAZA,
          MASTERS.PRODUCTIVA_TYPES.REGISTRO,
          MASTERS.PRODUCTIVA_TYPES.COLOR,
          MASTERS.PRODUCTIVA_TYPES.CRIADOR,
          MASTERS.PRODUCTIVA_TYPES.RODEO,
          MASTERS.PRODUCTIVA_TYPES.UBICACION,
        ],
        params: { activo: true },
        paramsByType: {
          [MASTERS.PRODUCTIVA_TYPES.UBICACION]: { is_padre: false },
        },
      },
    ],
    api.fetchMastersFormOptions,
    { enabled: visible }
  );
  const { data: females, isLoading: fetchingFemales } = useQuery(
    [
      'Units::females',
      { params: { sexo: UNITS.HEMBRAS, bajas: true, madres: true } },
    ],
    api.fetchUnits,
    { enabled: visible }
  );
  const { data: males, isLoading: fetchingMales } = useQuery(
    [
      'Units::males',
      { params: { sexo: UNITS.MACHOS, bajas: true, padres: true } },
    ],
    api.fetchUnits,
    { enabled: visible }
  );
  const { mutate: create, isLoading: creatingUnit } = useMutation(
    api.createUnit
  );
  const { mutate: edit, isLoading: editingUnit } = useMutation(api.editUnit);

  const initialValuesFormatted = useMemo(() => {
    // Initial Values when PARTO UNIT:
    if (initialValuesParto) {
      setBreed(initialValuesParto.data.raza);
      return {
        ...initialValuesParto.data,
        registro: MASTERS.getNAValue(
          options?.[MASTERS.PRODUCTIVA_TYPES.REGISTRO],
          initialValuesParto.data.raza
        ),
        color: MASTERS.getNAValue(
          options?.[MASTERS.PRODUCTIVA_TYPES.COLOR],
          initialValuesParto.data.raza
        ),
        fecha_nacimiento:
          initialValuesParto.data.fecha_nacimiento &&
          moment(initialValuesParto.data.fecha_nacimiento),
        created_at:
          initialValuesParto.data.fecha_nacimiento &&
          moment(initialValuesParto.data.fecha_nacimiento),
      };
    }
    // Initial Values when EDIT UNIT:
    if (initialValues) {
      setBreed(initialValues.raza);
      return {
        ...initialValues,
        fecha_nacimiento:
          initialValues.fecha_nacimiento &&
          moment(initialValues.fecha_nacimiento),
        created_at:
          initialValues.created_at && moment(initialValues.created_at),
      };
    }
    // Initial Values when ADD UNIT:
    return { created_at: moment() };
  }, [options, initialValues, initialValuesParto]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [form, initialValuesFormatted, visible]);

  const getCategoryOptions = () => {
    if (initialValuesParto) {
      return UNITS.unitOptionsByType(api.userSpecies, UNITS.CRIA);
    }
    return UNITS.unitOptionsByType(api.userSpecies);
  };

  const onChangeCategory = (category) => {
    setUnitType(UNITS.getType(api.userSpecies, category));
  };

  const onChangeBreed = (breed) => {
    setBreed(breed);
    form.setFieldsValue({
      registro: MASTERS.getNAValue(
        options?.[MASTERS.PRODUCTIVA_TYPES.REGISTRO],
        breed
      ),
      color: MASTERS.getNAValue(
        options?.[MASTERS.PRODUCTIVA_TYPES.COLOR],
        breed
      ),
    });
  };

  const cancel = () => {
    onCancel && onCancel();
  };
  const success = () => {
    onSuccess && onSuccess();
  };

  const onFinish = (values) => {
    const purgedValues = _.mapValues(values, (v) => v ?? null);
    const formattedUnit = {
      ...purgedValues,
      sex: unitType,
      fecha_nacimiento: GENERAL.dateToBack(values['fecha_nacimiento']),
      created_at: GENERAL.dateToBack(values['created_at']),
      parto: initialValuesParto?.id,
    };
    const onError = ({ message, description }) => {
      switch (message) {
        case '400':
          antMessage.error(description);
          break;
        case '403':
          antMessage.error(
            'Ha alcanzado la cantidad máxima de Madres que permite su licencia'
          );
          break;
        default:
          antMessage.error('ERROR');
      }
    };
    if (isFormEditing) {
      edit(
        { unitId: initialValues.id, unit: formattedUnit },
        { onSuccess: success, onError }
      );
    } else {
      const showDisplay = () => {
        displayInfoModal({
          description: 'Se agregó la unidad con éxito',
          showCheck: true,
          onOk: success,
        });
      };
      create(
        { unitType, unit: formattedUnit },
        { onSuccess: showDisplay, onError }
      );
    }
  };

  const renderFooter = () => (
    <Row gutter={16} className="units-add-form-footer">
      <Col span="12">
        <FormItem>
          <Button
            htmlType="button"
            className="units-add-form-button"
            onClick={cancel}
          >
            {initialValues ? 'VOLVER' : 'CANCELAR'}
          </Button>
        </FormItem>
      </Col>
      <Col span="12">
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            className="units-add-form-button"
            loading={creatingUnit || editingUnit}
          >
            {initialValues ? 'MODIFICAR' : 'AGREGAR'}
          </Button>
        </FormItem>
      </Col>
    </Row>
  );

  if (!options) return <Spin size="large" className="units-add-form-loading" />;

  return (
    <Form
      form={form}
      className="units-add-form"
      layout="vertical"
      requiredMark={false}
      wrapperCol={{ span: 24 }}
      onFinish={onFinish}
      initialValues={{
        ubicacion_geografica: MASTERS.getSinAsignarId(
          options?.[MASTERS.PRODUCTIVA_TYPES.UBICACION]
        ),
        rodeo: MASTERS.getSinAsignarId(
          options?.[MASTERS.PRODUCTIVA_TYPES.RODEO]
        ),
        ...initialValuesFormatted,
      }}
    >
      <Row gutter={16}>
        <Col span="24">
          <FormItem
            name="origen"
            rules={[{ required: true, message: 'Seleccione origen' }]}
          >
            <Radio.Group
              disabled={initialValuesParto}
              className="units-add-form-radio"
              options={options[MASTERS.PRODUCTIVA_TYPES.ORIGEN]}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="8">
          <FormItem
            label="RP"
            name="rp"
            rules={[{ required: true, message: 'Ingrese RP' }]}
          >
            <Input className="units-add-form-input" />
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="ID electrónico" name="id_electronico">
            <Input className="units-add-form-input" />
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="Nro. trazabilidad" name="nro_trazabilidad">
            <Input className="units-add-form-input" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="12">
          <FormItem
            label="Apodo"
            name="apodo"
            rules={[{ required: false, message: 'Ingrese apodo' }]}
          >
            <Input className="units-add-form-input" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem
            label="Nombre"
            name="nombre"
            rules={[{ required: false, message: 'Ingrese nombre' }]}
          >
            <Input className="units-add-form-input" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="12">
          <FormItem
            label="Fecha de nacimiento"
            name="fecha_nacimiento"
            rules={[{ required: true, message: 'Ingrese fecha de nacimiento' }]}
          >
            <DatePicker
              className="units-add-form-input"
              format={GENERAL.DATE_FORMAT.FRONT}
            />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem
            label="Categoría"
            name="categoria"
            rules={[
              {
                required: isFormEditing ? false : true,
                message: 'Ingrese categoria',
              },
            ]}
          >
            <Select
              disabled={isFormEditing}
              className="units-add-form-input"
              options={getCategoryOptions()}
              onChange={onChangeCategory}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="12">
          <FormItem
            label="Peso al nacer"
            name="peso_nacimiento"
            rules={[{ required: initialValuesParto, message: 'Ingrese peso' }]}
          >
            <InputNumber className="units-add-form-input" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem
            label="Peso destete"
            name="peso_destete"
            rules={[{ required: false, message: 'Ingrese peso' }]}
          >
            <InputNumber
              disabled={initialValuesParto}
              className="units-add-form-input"
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="8">
          <FormItem
            label="Raza"
            name="raza"
            rules={[{ required: true, message: 'Ingrese raza' }]}
          >
            <Select
              className="units-add-form-input"
              options={options[MASTERS.PRODUCTIVA_TYPES.RAZA]}
              onChange={onChangeBreed}
            />
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem
            label="Registro"
            name="registro"
            rules={[{ required: true, message: 'Ingrese registro' }]}
          >
            <Select
              className="units-add-form-input"
              options={_.filter(options?.[MASTERS.PRODUCTIVA_TYPES.REGISTRO], [
                'raza',
                breed,
              ])}
            />
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem
            label="Color"
            name="color"
            rules={[{ required: true, message: 'Ingrese color' }]}
          >
            <Select
              className="units-add-form-input"
              options={_.filter(options?.[MASTERS.PRODUCTIVA_TYPES.COLOR], [
                'raza',
                breed,
              ])}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="12">
          <FormItem
            label="RP Madre"
            name="madre"
            rules={[{ required: false, message: 'Ingrese RP Madre' }]}
          >
            <Select
              showSearch
              allowClear
              disabled={initialValuesParto}
              className="units-add-form-input"
              loading={fetchingFemales}
              optionFilterProp="label"
              options={GENERAL.toRPOptions(females?.results, { value: 'id' })}
            />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem
            label="RP Padre"
            name="padre"
            rules={[{ required: false, message: 'Ingrese RP Padre' }]}
          >
            <Select
              showSearch
              allowClear
              className="units-add-form-input"
              loading={fetchingMales}
              optionFilterProp="label"
              options={GENERAL.toRPApodoOptions(males?.results)}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="8">
          <FormItem
            label="HBA"
            name="hba"
            rules={[{ required: false, message: 'Ingrese HBA' }]}
          >
            <Input className="units-add-form-input" />
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem
            label="Identificación asociación"
            name="id_asociacion"
            rules={[
              { required: false, message: 'Ingrese identificación asociación' },
            ]}
          >
            <Input className="units-add-form-input" />
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem
            label="Fecha de alta"
            name="created_at"
            rules={[{ required: true, message: 'Ingrese fecha de alta' }]}
          >
            <DatePicker
              className="units-add-form-input"
              format={GENERAL.DATE_FORMAT.FRONT}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="12">
          <FormItem
            label="Ubicación"
            name="ubicacion_geografica"
            rules={[{ required: true, message: 'Ingrese ubicación' }]}
          >
            <Select
              allowClear
              className="units-add-form-input"
              options={options[MASTERS.PRODUCTIVA_TYPES.UBICACION]}
            />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem
            label="Rodeo"
            name="rodeo"
            rules={[{ required: true, message: 'Ingrese rodeo' }]}
          >
            <Select
              allowClear
              className="units-add-form-input"
              options={options[MASTERS.PRODUCTIVA_TYPES.RODEO]}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span="12">
          <FormItem
            label="Criador"
            name="criador"
            rules={[{ required: false, message: 'Ingrese criador' }]}
          >
            <Select
              allowClear
              className="units-add-form-input"
              options={options[MASTERS.PRODUCTIVA_TYPES.CRIADOR]}
            />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem
            label="Propietario"
            name="propietario"
            rules={[{ required: false, message: 'Ingrese propietario' }]}
          >
            <Select
              allowClear
              className="units-add-form-input"
              options={options[MASTERS.PRODUCTIVA_TYPES.CRIADOR]}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="CUT"
            name="cut"
            rules={[{ required: false, message: 'Ingrese CUT' }]}
          >
            <Select
              disabled={unitType !== UNITS.HEMBRAS}
              style={{ width: '100%' }}
              optionFilterProp="label"
              options={MASTERS.BOOLEAN_OPTIONS}
            />
          </Form.Item>
        </Col>
      </Row>
      {renderFooter()}
    </Form>
  );
};

export default withAPI(UnitsAddForm);
