import React from 'react';
import { useQuery } from 'react-query';
import { withAPI } from 'modules/api';
import { Col, Row } from 'antd';
import { usePaginationWithFilters } from 'modules/core/hooks';
import { GENERAL, PRODUCTIVA } from 'modules/core/constants';
import { Card, Charts } from 'modules/core/components';
import FilterSider, {
  UnitFilter,
  getRecordFilters,
} from 'modules/core/components/Filter';

const HEIGHT_ROW_1 = 250;
const HEIGHT_ROW_2 = 550;

const PesajeAnalytics = ({ api, isActive }) => {
  const { filters } = usePaginationWithFilters();
  const {
    data: pesajeGanaciaDiariaVariable,
    isLoading: isLoadingPesajeGanaciaDiariaVariable,
  } = useQuery(
    ['Analytics::fetchPesajeGanaciaDiariaVariable', { params: filters.get() }],
    api.fetchPesajeGanaciaDiariaVariable,
    { enabled: isActive }
  );
  const {
    data: pesajeGanaciaDiariaTotal,
    isLoading: isLoadingPesajeGanaciaDiariaTotal,
  } = useQuery(
    ['Analytics::fetchPesajeGanaciaDiariaTotal', { params: filters.get() }],
    api.fetchPesajeGanaciaDiariaTotal,
    { enabled: isActive }
  );
  const {
    data: pesajeGanaciaDiariaTotalByPadre,
    isLoading: isLoadingPesajeGanaciaDiariaTotalByPadre,
  } = useQuery(
    [
      'Analytics::fetchPesajeGanaciaDiariaTotalByPadre',
      { params: filters.get() },
    ],
    api.fetchPesajeGanaciaDiariaTotalByPadre,
    { enabled: isActive }
  );
  const { data: pesajeEvolucion, isLoading: isLoadingPesajeEvolucion } =
    useQuery(
      ['Analytics::fetchPesajeEvolucion', { params: filters.get() }],
      api.fetchPesajeEvolucion,
      { enabled: isActive }
    );
  const {
    data: pesajeIndividualEvolucion,
    isLoading: isLoadingPesajeIndividualEvolucion,
  } = useQuery(
    ['Analytics::fetchPesajeIndividualEvolucion', { params: filters.get() }],
    api.fetchPesajeIndividualEvolucion,
    { enabled: isActive }
  );
  const {
    data: pesajeGananciaDiariaEvolucion,
    isLoading: isLoadingPesajeGananciaDiariaEvolucion,
  } = useQuery(
    [
      'Analytics::fetchPesajeGananciaDiariaEvolucion',
      { params: filters.get() },
    ],
    api.fetchPesajeGananciaDiariaEvolucion,
    { enabled: isActive }
  );

  return (
    <>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={24}>
          <FilterSider
            showDrawer
            showCurrentYear
            showGlobalSearch={false}
            filters={[UnitFilter, ...getRecordFilters(PRODUCTIVA.PESAJE)]}
            onChange={filters.set}
          />
        </Col>
        <Col span={12}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={isLoadingPesajeGanaciaDiariaVariable}
            title={pesajeGanaciaDiariaVariable?.titulo}
            value={pesajeGanaciaDiariaVariable?.data}
            tooltip={pesajeGanaciaDiariaVariable?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
        <Col span={12}>
          <Charts.KPI
            height={HEIGHT_ROW_1}
            loading={isLoadingPesajeGanaciaDiariaTotal}
            title={pesajeGanaciaDiariaTotal?.titulo}
            value={pesajeGanaciaDiariaTotal?.data}
            tooltip={pesajeGanaciaDiariaTotal?.calculo}
            stylesKPI={{ value: { fontSize: 60 } }}
          />
        </Col>
      </Row>
      <Row gutter={[24, 32]} className="data-row">
        <Col span={12}>
          <Card
            type="secondary"
            loading={isLoadingPesajeGanaciaDiariaTotalByPadre}
            title={pesajeGanaciaDiariaTotalByPadre?.titulo}
            tooltip={pesajeGanaciaDiariaTotalByPadre?.calculo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Charts.TreeMap
              data={pesajeGanaciaDiariaTotalByPadre?.data}
              identity="label"
              value="value"
              label="id"
              enableParentLabel={false}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            type="secondary"
            loading={isLoadingPesajeEvolucion}
            title={pesajeEvolucion?.titulo}
            tooltip={pesajeEvolucion?.calculo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Charts.Line
              data={pesajeEvolucion?.data}
              colors={['#74c476', '#f47560']}
              axisLeft={{ legend: 'Peso' }}
              yFormat=">-.0f"
              enablePoints={false}
              enableGridX={false}
              axisBottom={{
                tickValues: GENERAL.generateTickValues(
                  pesajeEvolucion?.data[0]?.data
                ),
              }}
              legends={[]}
              yMaxValue={pesajeEvolucion?.yMaxValue}
              yMinValue={pesajeEvolucion?.yMinValue}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            type="secondary"
            loading={isLoadingPesajeIndividualEvolucion}
            title={pesajeIndividualEvolucion?.titulo}
            tooltip={pesajeIndividualEvolucion?.calculo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Charts.Line
              data={pesajeIndividualEvolucion?.data}
              colors={['#74c476', '#f47560']}
              axisLeft={{ legend: 'Peso' }}
              yFormat=">-.0f"
              enablePoints={false}
              enableGridX={false}
              axisBottom={{
                tickValues: GENERAL.generateTickValues(
                  pesajeIndividualEvolucion?.data[0]?.data
                ),
              }}
              legends={[]}
              yMaxValue={pesajeIndividualEvolucion?.yMaxValue}
              yMinValue={pesajeIndividualEvolucion?.yMinValue}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            type="secondary"
            loading={isLoadingPesajeGananciaDiariaEvolucion}
            title={pesajeGananciaDiariaEvolucion?.titulo}
            tooltip={pesajeGananciaDiariaEvolucion?.calculo}
            style={{ height: HEIGHT_ROW_2 }}
          >
            <Charts.Line
              data={pesajeGananciaDiariaEvolucion?.data}
              colors={['#74c476', '#f47560']}
              axisLeft={{ legend: 'Peso' }}
              yFormat=">-.0f"
              enablePoints={false}
              enableGridX={false}
              axisBottom={{
                tickValues: GENERAL.generateTickValues(
                  pesajeGananciaDiariaEvolucion?.data[0]?.data
                ),
              }}
              legends={[]}
              yMaxValue={pesajeGananciaDiariaEvolucion?.yMaxValue}
              yMinValue={pesajeGananciaDiariaEvolucion?.yMinValue}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withAPI(PesajeAnalytics);
